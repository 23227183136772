exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-index-tsx": () => import("./../../../src/pages/language/index.tsx" /* webpackChunkName: "component---src-pages-language-index-tsx" */),
  "component---src-pages-language-resources-portuguese-tsx": () => import("./../../../src/pages/language/resources/portuguese.tsx" /* webpackChunkName: "component---src-pages-language-resources-portuguese-tsx" */),
  "component---src-pages-language-resources-spanish-tsx": () => import("./../../../src/pages/language/resources/spanish.tsx" /* webpackChunkName: "component---src-pages-language-resources-spanish-tsx" */),
  "component---src-pages-language-verbs-tsx": () => import("./../../../src/pages/language/verbs.tsx" /* webpackChunkName: "component---src-pages-language-verbs-tsx" */),
  "component---src-pages-referrals-tsx": () => import("./../../../src/pages/referrals.tsx" /* webpackChunkName: "component---src-pages-referrals-tsx" */),
  "component---src-pages-tech-tsx": () => import("./../../../src/pages/tech.tsx" /* webpackChunkName: "component---src-pages-tech-tsx" */),
  "component---src-templates-location-page-tsx": () => import("./../../../src/templates/location-page.tsx" /* webpackChunkName: "component---src-templates-location-page-tsx" */)
}

